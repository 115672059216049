.consoleWrapper {
    position: relative;
    height: 400px;
    width: 100%;
    z-index: 10;
    backdrop-filter: blur(0px);
}

.background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.content {
    height: 80%;
    
}

.consoleText {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 35px;
    line-height: 70px;
    font-weight: bold;
    white-space: pre;
    margin-left: 10px;
}

.avatarWrapper {
    padding: 30px 50px;
}
.nftAvatar {
    width: 80%;
    height: 80%;
}

@media screen and (max-width: 1499px) {
    .consoleWrapper {
        height: 330px;
    }
}

@media screen and (max-width: 1249px) {
    .consoleWrapper {
        height: 220px;
        width: 100%;
    }

    .consoleText {       
        font-size: 18px;
    }
}


@media screen and (max-width: 991px) {
    .consoleWrapper{
        width:100%;
        min-width: 395px;
    }

    .consoleText {      
        font-size: 26px;
    }
}

@media screen and (max-width: 767px) {
    .consoleWrapper{
        min-width: 340px;
    }

    .consoleText {        
        font-size: 22px;
    }
}

@media screen and (max-width: 479px) {
    .consoleWrapper{
        min-width: 250px;
    }

    .consoleText {       
        font-size: 16px;
    }
}

@media screen and (max-width: 390px) {
    .consoleWrapper{
        min-width: 230px;
    }

    .consoleText {       
        font-size: 15px;
    }
}
