.itemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF1E;
    border-radius: 12px;
    margin: 60px 15px 0;
    height: 280px;
    transition: all .5s;
}

.slider-content {
    margin: 0px auto;
}

.slick-center .itemContainer {
    margin: 45px 0 0;
    height: 320px
}

.slickItemImage {
    height: 280px;
}

@media screen and (max-width: 1499px) {
    .slick-center .itemContainer {
        margin: 30px 0 0;
        height: 260px
    }

    .itemContainer {
        height: 220px;
    }
    .slickItemImage {
        height: 220px;
    }
}
@media screen and (max-width: 1249px) {
    .slick-center .itemContainer {
        margin: 10px 0px 0px;
        height: 200px;
        min-width: 190px;
    }

    .itemContainer {
        height: 160px;
    }
    .slickItemImage {
        height: 160px;
    }
}   

@media screen and (max-width: 1024px){
    .slick-center .itemContainer {
        margin: 25px 0px 0px;
        height: 160px;
        width: 160px;
        min-width: 150px;
    }

    .itemContainer{
        height: 120px;
    }

    .slickItemImage{
        height: 120px;
    }
}

@media screen and (max-width: 991px) {
    .slick-center .itemContainer {
        margin: 20px 0 0;
        height: 170px;
        width: auto;
    }

    .itemContainer {
        margin: 40px 10px 0;
        height: 150px;
    }
    .slickItemImage {
        height: 150px;
    }
}
@media screen and (max-width: 767px) {
    .slick-center .itemContainer {
        margin: 10px 0 0;
        height: 200px
    }

    .itemContainer {
        margin: 40px 10px 0;
        height: 180px;
    }
    .slickItemImage {
        height: 180px;
    }
}

@media screen and (max-width: 479px) {

    .slick-center .itemContainer {
        margin: 20px 0 0;
        height: 180px
    }

    .itemContainer {
        margin: 40px 10px 0;
        height: 160px;
    }
    .slickItemImage {
        height: 160px;
    }
}
