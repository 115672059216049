@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600&family=Poppins:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Space Ranger';
  src:
    local('Space Ranger'),
    url('./space-rangle/spaceranger.ttf') format('opentype');
}

@font-face {
  font-family: 'Space Age';
  src:
    local('Space Age'),
    url('./space-age/space-age.ttf') format('opentype');
}

@font-face {
  font-family: 'Neuropol';
  font-style: normal;
  font-weight: normal;
  src: local('Neuropol'), url('./neuropol/neuropol.otf') format('opentype');
}

@font-face{
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url('./Poppins/Poppins-Regular.ttf') format('opentype');
}

@font-face{
  font-family: 'Geom';
  src: 
    local('Geom'),
    url('./Geom/Geom-Variable.ttf') format('opentype');
}