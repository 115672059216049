.buttonWrapper {
    position: relative;
    cursor: pointer;
    user-select: none;
}

.buttonWrapper:hover {
    opacity: 0.8;
}

.buttonWrapper:active {
    opacity: 0.6;
}

.inputWrapper {
    user-select: none;
}

.buttonCaption {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
}

.background {
    backdrop-filter: blur(1px);
    height: 70px;
    width: 100%;
}

.crossImage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    max-height: 50px;
    transform: translate(-50%, -50%);
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(99.53deg, rgba(71, 195, 193, 0.6) 0%, rgba(245, 245, 245, 0.2) 100%);
    border-radius: 5px;
    height: 70px;
    width: 70px;
    min-width: 40px;
    cursor: pointer;
    user-select: none;
}
.button:hover {
    background-color: #1d5051;
}

.quantityText {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: linear-gradient(99.53deg, rgba(71, 195, 193, 0.6) 0%, rgba(245, 245, 245, 0.2) 100%);
    border: 2px solid #F5F5F5;
    border-radius: 5px;
}

.buttonIcon {
    width: 50px;
}

@media screen and (max-width: 1249px) {
    .background {
        height: 55px;
    }

    .button {
        height: 55px;
        width: 55px;
    }

    .buttonCaption {
        font-size: 18px;
    }

    .quantityText {
        height: 55px;
    }
}

@media screen and (max-width: 991px) {
    .background {
        height: 50px;
    }

    .button {
        height: 50px;
        width: 50px;
        padding: 6px;
    }

    .quantityText {
        height: 50px;
    }

    .buttonIcon {
        width: 40px;
    }
}

@media screen and (max-width: 767px) {
    .buttonCaption {
        font-size: 15px;
    }

    .buttonIcon {
        width: 35px;
    }
}

@media screen and (max-width: 479px) {
    .buttonCaption {
        font-size: 15px;
        padding-left: 10px;
    }
}

