body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiList-root {
  background-color: #333;
}

.gradient-border-box {
  position: relative;
}

.gradient-border-box::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(150deg, #47c3c1, #7b3fe4);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}

button,
p,
a,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Neuropol';
}

button {
  text-transform: unset !important;
}

div,
textarea,
button,
p {
  box-sizing: border-box;
}
