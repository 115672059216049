.consoleWrapper {
    position: relative;
    height: 285px;
    border-radius: 20px;
    border: 3px solid #F5F5F5;
    background: linear-gradient(91.73deg, rgba(71, 195, 193, 0.6) 10.59%, rgba(71, 195, 193, 0.2) 100%);
    width: 100%;
    backdrop-filter: blur(10px);
 
}

.decorator {
    position: absolute;
    left: -140px;
    width: 140px;
    top: 30px;
}

.star {
    position: absolute;
    bottom: 15px;
    right: 15px;
    opacity: .25;
}

.textWrapper {
    color: white;
    height: 90%;
    display:flex;
    font-size: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.textWrapperConsole {
    color: white;
    height: 90%;
    display:flex;
    font-size: 40px;
    font-weight: bold;
    padding: 0px 10px 0px 20px;
}

@media screen and (max-width: 1499px) {
    .textWrapper {
        font-size: 40px;
    }

    .textWrapperConsole {
        font-size: 40px;
    }
}

@media screen and (max-width: 1249px) {
    .consoleWrapper{
        height: calc(0.389 * 100% + 1.212px);
    }

    .decorator {
        left: -100px;
        width: 100px;
    }

    .textWrapper {
        font-size: 34px;
    }

    .textWrapperConsole {
        font-size: 34px;
    }

    .star{
        width: 80px;
    }
}


@media screen and (max-width: 991px) {
    .decorator {
        left: -60px;
        width: 60px;
        height: 40px;
    }

    .star {
        width: 60px;
    }

    .consoleWrapper{
        height: calc(0.473 * 100% - 1.6px);
    }

    .textWrapper {
        font-size: 25px;
    }

    .textWrapperConsole {
        font-size: 25px;
    }
}

@media screen and (max-width: 767px) {
    .star {
        width: 70px;
    }

    .textWrapper {
        font-size: 30px;
    }

    .textWrapperConsole {
        font-size: 30px;
    }
}

@media screen and (max-width: 597px) {
    .textWrapper {
        font-size: 25px;
    }

    .star{
        width: 60px;
    }

    .textWrapperConsole {
        font-size: 25px;
    }
}

@media screen and (max-width: 479px) {
    .textWrapper {
        font-size: 23px;
    }

    .star{
        width: 50px;
    }

    .textWrapperConsole {
        font-size: 20px;
    }
}
