.pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 80px;
    max-height: 90vh;
    width: 100%;
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 230px);
    gap: 25px;
    margin-top: 30px;
}

.star {
    filter: drop-shadow(0 0 20px #47C3C1);
    height: calc(70% - 65px);
}

.opacity-25 {
    composes: star;
    opacity: .25;
}

.opacity-50 {
    composes: star;
    opacity: .5;
}

.opacity-75 {
    composes: star;
    opacity: .75;
}

.opacity-100 {
    composes: star;
    opacity: 1;
}

.progressBar {
    width: 530px;
}

@media screen and (max-width: 991px) {
    .star {
        height: 450px;
    }
}

@media screen and (max-width: 768px) {
    .star {
        height: 330px;
    }
}

@media screen and (max-width: 479px) {
    .star {
        height: 300px;
    }
}
