.pageWrapper {
    position: relative;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    overflow: visible;
}

.backgroundStar {
    position: absolute;
    width: 650px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 20px #47C3C1);
    opacity: .25;
}

.contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
}

.content {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-left: 10px;
}

.heroImage {
    height: 500px;
    backdrop-filter: blur(0px);
}

.mintWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 25px;
    width: 600px;
}

.crossMintButton {
    width: 90%;
    height: 100%;
    float: right;
    background: linear-gradient(99.53deg, rgba(71, 195, 193, 0.6) 0%, rgba(245, 245, 245, 0.2) 100%) !important;
}

.crossMintButton span {
    display: none;
}

.crossMintButton::after {
    content: "CrossMint";
    color: white;
    font-size: 30px;
    font-weight: 300;
}

@media screen and (max-width: 1499px) {
    .contentWrapper {
        padding-right: 0px;
    }

    .content{
        justify-content: space-between;
    }

    .heroImage {
        height: 450px;
    }

    .mintWrapper {
        width: 550px;
    }

    .mint {
        height: 60px;
    }

    .button {
        height: 60px;
        width: 60px;
    }

    .quantity {
        height: 60px;
    }

    .crossMintButton::after {
        font-size: 23px;
    }
}

@media screen and (max-width: 1249px) {

    .heroImage {
        height: 370px;
    }

    .mintWrapper {
        width: 420px;
    }

    .mint {
        height: 50px;
    }

    .button {
        height: 50px;
        width: 50px;
        background-image: url('../../../../../assets/images/MoonizenMint.png');
    }

    .buttonIcon {
        width: 100%;
    }

    .quantity {
        height: 50px;
    }

    .crossMintButton::after {
        font-size: 23px;
    }
}

@media screen and (max-width: 1024px) {
    .backgroundStar {
        max-width: 500px;
        width: 70%;
        top: 2%;
        transform: translateX(-50%);
    }

    .contentWrapper {
        align-items: center;
        overflow: visible;
    }

    .content{
        overflow: visible;
    }

    .heroImage {
        margin-top: 30px;
        height: 340px;
    }

    .mintWrapper {
        margin-top: 30px;
        flex-direction: column-reverse;
        width: 100%;
    }

  
    .crossMintButton::after {
        font-size: 23px;
    }
}

@media screen and (max-width: 767px) {

    .heroImage {
        height: 340px;
    }

    .crossMintButton::after {
        font-size: 23px;
    }
}

@media screen and (max-width: 597px) {

    .heroImage {
        margin-top: 20px;
        height: 320px;
    }

  
    .crossMintButton::after {
        font-size: 20px;
    }
}

@media screen and (max-width: 479px) {
    .heroImage {
        margin-top: 20px;
        height: 300px;
    }
    .crossMintButton::after {
        font-size: 20px;
    }
    
}

@media screen and (max-width: 390px) {
    .heroImage {
        margin-top: 20px;
        height: 250px;
    }

    .crossMintButton::after {
        font-size: 14px;
    }
    
}
