.logo {
    filter: drop-shadow(0 0 10px #47C3C1);
    width: 550px;
}

.logo.small {
    width: 200px;
}


@media screen and (max-width: 991px) {
    .logo {
        width: 370px;
    }
}

@media screen and (max-width: 767px) {
    .logo {
        width: 350px;
    }
}

@media screen and (max-width: 479px) {
    .logo {
        width: 300px;
    }
}
